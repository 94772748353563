import React from 'react';
import Services from './Services';
import About from '../About';


function ServicesPage() {
    return ( 
        <div>
            <Services/>
            <About/>
        </div>
     );
}

export default ServicesPage;