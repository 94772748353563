import React from "react";

function NotFound() {
  return (
    <div className="container p-5 mb-5">
      <div className="row text-center">
        <div className="mt-5">
          <h1>404 Not Found</h1>
          <p>Sorry, the page you are looking for does not exist.</p>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
