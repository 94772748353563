import React from 'react';

function Services() {
    return (
        <div>
            <h1>Services</h1>
        </div>
      );
}

export default Services;