import React from 'react';

function Expert() {
    return (
        <div>
            <h1>Expert</h1>
        </div>
      );
}

export default Expert;