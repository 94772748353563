import React from 'react';
import Expert from './Expert';
import About from '../About';


function ExpertPage() {
    return ( 
        <div>
            <Expert/>
            <About/>
        </div>
     );
}

export default ExpertPage;