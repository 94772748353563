import React from 'react';
import Register from './Register';
import About from '../About';

function SignupPage() {
    return ( 
        <div>
            <Register/>
            <About/>
        </div>
     );
}

export default SignupPage;