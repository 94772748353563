import React from "react";
import Meeting from "./Meeting";
import About from "../About";

function MeetingPage() {
  return (
    <div>
      <Meeting />
    </div>
  );
}

export default MeetingPage;
