import React from 'react';
import Login from './Login';


function SigninPage() {
    return ( 
        <div>
            <Login/>
        </div>
     );
}

export default SigninPage;