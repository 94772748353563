import React from 'react';

function Register() {
    return (
        <div>
            <h1>Register</h1>
        </div>
      );
}

export default Register;